import { Injectable } from '@angular/core';
import { IMapRecommendation } from '../models/map-recommendation.interface';
import { IMapEquipment } from '../models/map-equipment.interface';
import { IEquipmentRecommendation } from '../models/equipment-recommendation.interface';

@Injectable()
export class EquipmentRecommendationHelper {
  public static initRecommendationsStats() {
    const recommendations = new Map<string, IMapRecommendation>();

    recommendations.set('change-location', {
      key: 'change-location',
      label: 'Cambio de ubicación',
      quantity: 0,
      iconName: 'recommendation-change-location',
      active: false,
      filterClass: 'recomendationChangeLocation',
    });

    recommendations.set('change-battery', {
      key: 'change-battery',
      label: 'Cambio de batería',
      quantity: 0,
      iconName: 'recommendation-change-battery',
      active: false,
      filterClass: 'recomendationChangeBattery',
    });

    return recommendations;
  }

  public static setRecommendationsData(equipmentMapList: IMapEquipment[]): IMapEquipment[] {
    equipmentMapList.forEach((equipmentMap: IMapEquipment) => {

      equipmentMap.recommendations = this.getRecommendationsDataFromEquipment(equipmentMap);

      // if (equipmentMap.recomendationChangeBattery) {
      //   equipmentMap.recommendations.push({
      //     icon: '',
      //     key: 'change-battery',
      //     label: 'Cambio de batería',
      //     date: equipmentMap.recomendationChangeBatteryAt,
      //   });
      // }

      // if (equipmentMap.recomendationChangeLocation) {
      //   equipmentMap.recommendations.push({
      //     icon: '',
      //     key: 'change-location',
      //     label: 'Cambio de ubicación',
      //     date: equipmentMap.recomendationChangeLocationAt,
      //   });
      // }
    });

    return equipmentMapList;
  }

  public static getRecommendationsDataFromEquipment(equipmentMap: IMapEquipment): IEquipmentRecommendation[] {

    const recommendations = [];

    if (equipmentMap.recomendationChangeBattery) {
      recommendations.push({
        iconName: 'recommendation-change-battery',
        key: 'change-battery',
        label: 'Cambio de batería',
        date: equipmentMap.recomendationChangeBatteryAt,
      });
    }

    if (equipmentMap.recomendationChangeLocation) {
      recommendations.push({
        iconName: 'recommendation-change-location',
        key: 'change-location',
        label: 'Cambio de ubicación',
        date: equipmentMap.recomendationChangeLocationAt,
      });
    }

    return recommendations;
  }

  public static setRecommendationsStats2(mapEquipments: IMapEquipment[]) {
    const recommendationsStats = EquipmentRecommendationHelper.initRecommendationsStats();

    if (mapEquipments.length > 0) {
      mapEquipments.forEach((equipment) => {
        const recommendationChangeBattery = recommendationsStats.get('change-battery');
        if (recommendationChangeBattery) {
          recommendationChangeBattery.quantity += equipment.recomendationChangeBattery ? 1 : 0;
          recommendationsStats.set('change-battery', recommendationChangeBattery);
        }

        const recommendationChangeLocation = recommendationsStats.get('change-location');
        if (recommendationChangeLocation) {
          recommendationChangeLocation.quantity += equipment.recomendationChangeLocation ? 1 : 0;
          recommendationsStats.set('change-location', recommendationChangeLocation);
        }
      });
    }

    return recommendationsStats;
  }

  public static setRecommendationsStats(mapEquipments: IMapEquipment[]) {
    const recommendationsStats = EquipmentRecommendationHelper.initRecommendationsStats();

    if (mapEquipments.length > 0) {
      const issueMappings: { [key: string]: keyof IMapEquipment } = {
        'change-location': 'recomendationChangeLocation',
        'change-battery': 'recomendationChangeBattery',
      };

      mapEquipments.forEach((equipment) => {
        for (const [issue, property] of Object.entries(issueMappings)) {
          const issueStat = recommendationsStats.get(issue);
          if (issueStat) {
            issueStat.quantity += equipment[property] ? 1 : 0;
          }
        }
      });
    }

    return recommendationsStats;
  }
}
