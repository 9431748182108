import { Injectable } from "@angular/core";
import { IMapIssue } from "../models/map-issue.interface";
import { IMapEquipment } from "../models/map-equipment.interface";
import { IEquipmentIssue } from "../models/equipment-issue.interface";

@Injectable()
export class EquipmentIssueHelper {

  public static initIssuesStats() {

    const issues = new Map<string, IMapIssue>();

    issues.set('no-battery', {
      key: 'no-battery',
      label: 'Sin batería',
      quantity: 0,
      iconName: 'alert-no-battery',
      active: false,
      filterClass: 'alertNoBattery',
    });
    issues.set('dumped', {
      key: 'dumped',
      label: 'Contenedor volcado',
      quantity: 0,
      iconName: 'alert-dumped',
      active: false,
      filterClass: 'alertDumped',
    });
    issues.set('locked', {
      key: 'locked',
      label: 'No abre',
      quantity: 0,
      iconName: 'alert-locked',
      active: false,
      filterClass: 'alertLocked',
    });
    issues.set('communications', {
      key: 'communications',
      label: 'No comunica',
      quantity: 0,
      iconName: 'alert-communications',
      active: false,
      filterClass: 'alertCommunications',
    });
    issues.set('fire', {
      key: 'fire',
      label: 'Incendio',
      quantity: 0,
      iconName: 'alert-fire',
      active: false,
      filterClass: 'alertFire',
    });
    issues.set('damage-servo', {
      key: 'damage-servo',
      label: 'Servo estropeado',
      quantity: 0,
      iconName: 'alert-damage-servo',
      active: false,
      filterClass: 'alertDamageServo',
    });
    issues.set('open-cover', {
      key: 'open-cover',
      label: 'Tapa abierta',
      quantity: 0,
      iconName: 'alert-open-cover',
      active: false,
      filterClass: 'alertOpenCover',
    });

    return issues;
  }

  public static setIssuesData(equipmentMapList: IMapEquipment[]): IMapEquipment[] {

    equipmentMapList.forEach((equipmentMap: IMapEquipment) => {

      equipmentMap.issues = this.getIssuesDataFromEquipment(equipmentMap);

    //   equipmentMap.issues = [];

    //   if (equipmentMap.alertCommunications) {
    //     equipmentMap.issues.push({
    //       icon: '',
    //       key: 'communications',
    //       label: 'No comunica',
    //       date: equipmentMap.alertCommunicationsAt,
    //     });
    //   }

    //   if (equipmentMap.alertNoBattery) {
    //     equipmentMap.issues.push({
    //       icon: '',
    //       key: 'no-battery',
    //       label: 'Sin batería',
    //       date: equipmentMap.alertNoBatteryAt,
    //     });
    //   }

    //   if (equipmentMap.alertDumped) {
    //     equipmentMap.issues.push({
    //       icon: '',
    //       key: 'dumped',
    //       label: 'Contenedor volcado',
    //       date: equipmentMap.alertDumpedAt,
    //     });
    //   }

    //   if (equipmentMap.alertLocked) {
    //     equipmentMap.issues.push({
    //       icon: '',
    //       key: 'locked',
    //       label: 'No abre',
    //       date: equipmentMap.alertLockedAt,
    //     });
    //   }

    //   if (equipmentMap.alertFire) {
    //     equipmentMap.issues.push({
    //       icon: '',
    //       key: 'fire',
    //       label: 'Incendio',
    //       date: equipmentMap.alertFireAt,
    //     });
    //   }

    //   if (equipmentMap.alertDamageServo) {
    //     equipmentMap.issues.push({
    //       icon: '',
    //       key: 'damage-servo',
    //       label: 'Servo estropeado',
    //       date: equipmentMap.alertDamageServoAt,
    //     });
    //   }

    //   if (equipmentMap.alertOpenCover) {
    //     equipmentMap.issues.push({
    //       icon: '',
    //       key: 'open-cover',
    //       label: 'Tapa abierta',
    //       date: equipmentMap.alertOpenCoverAt,
    //     });
    //   }
    });

    return equipmentMapList;
  }

  public static getIssuesDataFromEquipment(equipmentMap: IMapEquipment): IEquipmentIssue[] {

    const issues = [];

    if (equipmentMap.alertCommunications) {
      issues.push({
        iconName: 'alert-communications',
        key: 'communications',
        label: 'No comunica',
        date: equipmentMap.alertCommunicationsAt,
      });
    }

    if (equipmentMap.alertNoBattery) {
      issues.push({
        iconName: 'alert-no-battery',
        key: 'no-battery',
        label: 'Sin batería',
        date: equipmentMap.alertNoBatteryAt,
      });
    }

    if (equipmentMap.alertDumped) {
      issues.push({
        iconName: 'alert-dumped',
        key: 'dumped',
        label: 'Contenedor volcado',
        date: equipmentMap.alertDumpedAt,
      });
    }

    if (equipmentMap.alertLocked) {
      issues.push({
        iconName: 'alert-locked',
        key: 'locked',
        label: 'No abre',
        date: equipmentMap.alertLockedAt,
      });
    }

    if (equipmentMap.alertFire) {
      issues.push({
        iconName: 'alert-fire',
        key: 'fire',
        label: 'Incendio',
        date: equipmentMap.alertFireAt,
      });
    }

    if (equipmentMap.alertDamageServo) {
      issues.push({
        iconName: 'alert-damage-servo',
        key: 'damage-servo',
        label: 'Servo estropeado',
        date: equipmentMap.alertDamageServoAt,
      });
    }

    if (equipmentMap.alertOpenCover) {
      issues.push({
        iconName: 'alert-open-cover',
        key: 'open-cover',
        label: 'Tapa abierta',
        date: equipmentMap.alertOpenCoverAt,
      });
    }

    return issues;
  }

  public static setIssuesStats2(mapEquipments: IMapEquipment[]) {

    const issuesStats = EquipmentIssueHelper.initIssuesStats();

    if (mapEquipments.length > 0) {

      mapEquipments.forEach((equipment) => {

        const alertNoBattery = issuesStats.get('no-battery');
        if (alertNoBattery) {
          alertNoBattery.quantity += equipment.alertNoBattery ? 1 : 0;
          issuesStats.set('no-battery', alertNoBattery);
        }

        const alertDumped = issuesStats.get('dumped');
        if (alertDumped) {
          alertDumped.quantity += equipment.alertDumped ? 1 : 0;
          issuesStats.set('dumped', alertDumped);
        }

        const alertLocked = issuesStats.get('locked');
        if (alertLocked) {
          alertLocked.quantity += equipment.alertLocked ? 1 : 0;
          issuesStats.set('locked', alertLocked);
        }

        const alertCommunications = issuesStats.get('communications');
        if (alertCommunications) {
          alertCommunications.quantity += equipment.alertCommunications ? 1 : 0;
          issuesStats.set('communications', alertCommunications);
        }

        const alertFire = issuesStats.get('fire');
        if (alertFire) {
          alertFire.quantity += equipment.alertFire ? 1 : 0;
          issuesStats.set('fire', alertFire);
        }

        const alertDamageServo = issuesStats.get('damage-servo');
        if (alertDamageServo) {
          alertDamageServo.quantity += equipment.alertDamageServo ? 1 : 0;
          issuesStats.set('damage-servo', alertDamageServo);
        }

        const alertOpenCover = issuesStats.get('open-cover');
        if (alertOpenCover) {
          alertOpenCover.quantity += equipment.alertOpenCover ? 1 : 0;
          issuesStats.set('open-cover', alertOpenCover);
        }

      });
    }

    return issuesStats;
  }

  public static setIssuesStats(mapEquipments: IMapEquipment[]) {

    const issuesStats = EquipmentIssueHelper.initIssuesStats();

    if (mapEquipments.length > 0) {

      const issueMappings: { [key: string]: keyof IMapEquipment } = {
        'no-battery': 'alertNoBattery',
        'dumped': 'alertDumped',
        'locked': 'alertLocked',
        'communications': 'alertCommunications',
        'fire': 'alertFire',
        'damage-servo': 'alertDamageServo',
        'open-cover': 'alertOpenCover'
      };

      mapEquipments.forEach((equipment) => {
        for (const [issue, property] of Object.entries(issueMappings)) {
          const issueStat = issuesStats.get(issue);
          if (issueStat) {
            issueStat.quantity += equipment[property] ? 1 : 0;
          }
        }
      });
    }

    return issuesStats;
  }
}
